import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";

import { Form } from '../../data/form';
import { AnswerFull, AnswerForms, AnswerSection, TabularAnswer } from '../../data/answer';
import { AnswerService } from '../../services/answer.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
  inspectionID: number = 0;
  forms: Form[] = [];
  answerForms: AnswerForms = <AnswerForms>{};
  dealerName: string = "";
  dealerCode: string = "";
  completedDate: string = "";
  aveCompletionTime: number[] = [];
  aveCompletionBayOut: number = 0;
  aveMax: number = 0;
  percentCompletionBayOut: number = 0;
  diffCompletionTime: number[] = [];
  percentCompletionTime: number[] = [];
  stepGoals: number[] = [5, 2, 8, 2, 10, 1, 9, 5, 3];
  stepColors: string[] = [
    "49, 100, 189",
    "229, 107, 22",
    "146, 146, 146",
    "228, 182, 0",
    "67, 138, 200",
    "97, 163, 53",
    "97, 163, 53",
    "97, 163, 53",
    "26, 58, 115",
  ];

  Object = window.Object;
  url = window.location.href;
  showGraph = false;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public funcs: FuncsService,
    private answerService: AnswerService
  ) {}

  ngOnInit(): void {
    const inspectionIDstring: string = this.route.snapshot.paramMap.get('inspectionID') || "";
    this.inspectionID = parseInt(inspectionIDstring, 10);
    this.stepGoals.push(this.stepGoals.reduce((a, b) => a + b, 0));
    this.getAnswers();
  }

  print(): void {
    window.print();
  }

  formatClassYesNo(formSectionLayout: string, answerText: string, answerTypeName: string): string {
    var classNames :string[] = [];
    if (formSectionLayout == "right-align") {
      classNames.push("right-align");
    }
    if (answerTypeName == "Yes/No") {
      if (answerText == "Y") {
        classNames.push("ynYes");
      } else {
        classNames.push("ynNo");
      }
    }
    return classNames.join(" ");
  }

  expandYesNo(yOrN: string, answerType: string): string {
    if (answerType == "Check Box") {
      if (yOrN == "Y") {
        return "✔";
      }
      return "";
    }

    if (answerType == "Yes/No") {
      if (yOrN == "Y") {
        return "Yes";
      }
      return "No";
    }

    if (answerType == "Number") {
      if (yOrN.length > 4) {
        return Number(yOrN).toLocaleString();
      }
    }
    return yOrN;
  }

  addColon(questionText: string): string {
    if (!questionText || questionText.trimRight() == "") {
      return "";
    }
    if (questionText.trimRight().slice(-1) == "?") {
      return "";
    }
    return ":";
  }

  tabularData: TabularAnswer[] = [];
  tabData(answers: AnswerFull[]): void {
    answers.forEach(a => {
      if (a.reportLayout == "tabular" || this.sectionSharedWithTab(a.reportLayout, a.formCode, answers) == "shared-tab") {
        var formQuestion = this.tabularData.find(td => td.formSectionId == a.formSectionID && td.formQuestionId == a.formQuestionID);
        if (formQuestion) {
          formQuestion.answerText.push(a.answerText);
        } else {
          this.tabularData.push({
            "formSectionId": a.formSectionID,
            "formQuestionId": a.formQuestionID,
            "questionText": a.questionText,
            "answerText": [a.answerText],
            "answerTypeName": a.answerTypeName
          });
        }
      }
    });
  }

  filterTab(formQuestionId: number): string[] {
    var emptyTabAnswer: TabularAnswer = <TabularAnswer>{}
    emptyTabAnswer.answerText = [];
    var tabAnswer = this.tabularData.find(td => formQuestionId == td.formQuestionId) || emptyTabAnswer;
    return tabAnswer.answerText;
  }

  filterSharedTab(formSectionId: number): TabularAnswer[] {
    var emptyTabAnswer: TabularAnswer = <TabularAnswer>{}
    emptyTabAnswer.answerText = [];
    var sharedTabAnswers = this.tabularData.filter(td => formSectionId == td.formSectionId) || emptyTabAnswer;
    return sharedTabAnswers;
  }

  calculateAnswers(answers: AnswerFull[]): AnswerFull[] {
    return answers.map(a => {
      if (a.answerTypeName == "Calculated") {
        a.answerText = this.funcs.solveFormula(a.formula || "", answers.filter(at => {
          return a.tabIndex == at.tabIndex;
        }));
        return a;
      }
      return a;
    });
  }

  sectionSharedWithTab(reportLayout: string, formCode: string, answers: AnswerFull[]): string {
    if (reportLayout == "tabular") {
      return "tabular";
    }
    if (answers.some(a => a.reportLayout == "tabular" && formCode == a.formCode)) {
      return "shared-tab";
    }
    return reportLayout;
  }

  percentClass(): string {
    return "";
  }

  getDiffClass(diff: number): string {
    if (diff > 0) {
      return "diff-arrow-up";
    } 
    if (diff < 0) {
      return "diff-arrow-down";
    } 
    return "diff-arrow";
  }

  timeDiff(timeA: string, timeB: string): number {
    return this.funcs.dateFromTime(timeB) - this.funcs.dateFromTime(timeA);
  }

  calculateStepGoals(): void {
    var flowSegmentDurations = this.tabularData.filter(d => d.formSectionId == 4).map(d => d.answerText);

    flowSegmentDurations.forEach((d, idx) => {
      var totalDuration = 0;
      var n = 0;
      var mean: number = 0;
      if (idx < flowSegmentDurations.length - 2) {
        d.forEach((t, iT) => {
          if (t && flowSegmentDurations[idx + 1][iT]) {
            totalDuration += this.timeDiff(t, flowSegmentDurations[idx + 1][iT]);
            n += 1;
          }
        });
        mean = totalDuration / n / 60000;
        this.aveCompletionTime.push(mean);
        this.diffCompletionTime.push(-100 * (mean - this.stepGoals[idx]) / this.stepGoals[idx]);
      } else if (idx < flowSegmentDurations.length - 1) {
        d.forEach((t, iT) => {
          totalDuration += this.timeDiff(flowSegmentDurations[0][iT], t);
          n += 1;
        });
        mean = totalDuration / n / 60000;
        this.aveCompletionTime.push(mean);
        this.diffCompletionTime.push(-100 * (mean - this.stepGoals[idx]) / this.stepGoals[idx]);
      }
    });

    // var aveMax = 0;
    this.aveCompletionTime.forEach((a, idx, act) => {
      if (a > this.aveMax && idx < act.length - 1) {
        this.aveMax = a;
      }
    });
    this.aveCompletionTime.forEach(t => {
      this.percentCompletionTime.push(100 * t / this.aveMax);
    });
    this.aveCompletionBayOut = this.aveCompletionTime[5] + this.aveCompletionTime[6] + this.aveCompletionTime[7];
    this.percentCompletionBayOut = 100 * this.aveCompletionBayOut / this.aveMax;
  }

  getPercentStyle(idx: number): string {
    if (idx >= this.percentCompletionTime.length) {
      return `linear-gradient(to left, white ${100 - this.percentCompletionBayOut}%, rgba(${this.stepColors[5]}, 0.5) ${this.percentCompletionBayOut}%)`;;;
    }
    return `linear-gradient(to left, white ${100 - this.percentCompletionTime[idx]}%, rgba(${this.stepColors[idx]}, 0.5) ${this.percentCompletionTime[idx]}%)`;
  }

  getAnswers(): void {
    this.answerService.getAnswersFull(this.inspectionID).subscribe(answers => {
      answers = this.calculateAnswers(answers);
      if (answers.length < 1) {
        return;
      }
      this.dealerName = answers[0].dealerName;
      this.dealerCode = answers[0].dealerCode;
      this.completedDate = answers[0].completedDate;
      this.tabData(answers);
      answers.forEach(a => {
        if (!this.answerForms[a.formCode]) {
          this.answerForms[a.formCode] = {
            formName: a.formName,
            formCode: a.formCode,
            maxInstances: a.maxInstances,
            answerSections: []
          };
          this.forms.push(<Form>{
            "formID": a.formID,
            "formCode": a.formCode,
            "formName": a.formName,
            "formDesc": "",
            "formDisplayed": a.formDisplayed
          });

          if ((a.reportLayout != 'tabular' && this.sectionSharedWithTab(a.reportLayout, a.formCode, answers) != "shared-tab") || !this.answerForms[a.formCode].answerSections.some(as => as.formSectionId == a.formSectionID)) {
            this.answerForms[a.formCode].answerSections.push(<AnswerSection>{
              formSectionId: a.formSectionID,
              formTabIndex: a.tabIndex,
              formSectionTitle: a.title,
              formSectionDescription: a.desc,
              formSectionLayout: a.reportLayout,
              formSectionReportLayout: this.sectionSharedWithTab(a.reportLayout, a.formCode, answers),
              formSectionAnswers: [a]
            });
          }
        } else {
          var formSection = this.answerForms[a.formCode].answerSections.find(f => {
            return f.formSectionId === a.formSectionID && f.formTabIndex === a.tabIndex;
          });

          if (formSection) {
            formSection.formSectionAnswers.push(a);
          } else {
            if ((a.reportLayout != 'tabular' && this.sectionSharedWithTab(a.reportLayout, a.formCode, answers) != 'shared-tab') || !this.answerForms[a.formCode].answerSections.some(as => as.formSectionId == a.formSectionID)) {
              this.answerForms[a.formCode].answerSections.push(<AnswerSection>{
                formSectionId: a.formSectionID,
                formTabIndex: a.tabIndex,
                formSectionTitle: a.title,
                formSectionDescription: a.desc,
                formSectionLayout: a.reportLayout,
                formSectionReportLayout: this.sectionSharedWithTab(a.reportLayout, a.formCode, answers),
                formSectionAnswers: [a]
              });
            }
          }
        }
      });
      
      this.titleService.setTitle(`${this.funcs.capitalize(this.dealerName)} - Helm Service Consulting`);
      this.calculateStepGoals();
    });
  }
}
