import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserCurrent } from '../data/user';
import { AnswerFull } from '../data/answer';

@Injectable({
  providedIn: 'root'
})

export class FuncsService {
  constructor(private router: Router) { }

  roles: string[] = ["ServiceConsultant", "ServiceConsultantAdmin"];

  currentUser(user: User): void {
    var d;

    if (!user || !user.roleID) {
      d = window.localStorage.getItem("user");
      if (!d) {
        this.router.navigateByUrl('/login');
        return;
      }
      d = JSON.parse(d);
      if (this.roles.indexOf(d.roleName) < 0) {
        this.router.navigateByUrl('/login');
        return;
      }

      UserCurrent.userID = d.userID;
      UserCurrent.roleID = d.roleID;
      UserCurrent.roleName = d.roleName;
    }
  }

  setUser(user: User): void {
    UserCurrent.userID = user.userID;
    UserCurrent.roleID = user.roleID;
    UserCurrent.roleName = user.roleName;

    // persist data
    var userStore = {
      userID: UserCurrent.userID,
      roleID: UserCurrent.roleID,
      roleName: UserCurrent.roleName
    };
    window.localStorage.setItem("user", JSON.stringify(userStore));
  }

  logOutUser(): void {
    window.localStorage.setItem("user", "{}");

    UserCurrent.userID = "";
    UserCurrent.roleID = 0;
    UserCurrent.roleName = "";

    // persist data
    var userStore = {
      userID: "",
      roleID: 0,
      roleName: ""
    };
    window.localStorage.setItem("user", JSON.stringify(userStore));

    this.router.navigateByUrl('/login');
  }

  isAdmin(): boolean {
    this.currentUser(UserCurrent);
    return UserCurrent.roleName === "ServiceConsultantAdmin";
  }

  checkAdmin(): void {
    this.currentUser(UserCurrent);

    if (UserCurrent.roleName === "ServiceConsultant") {
      this.router.navigateByUrl(`/home/${UserCurrent.userID}`);
    }
    if (UserCurrent.roleName !== "ServiceConsultantAdmin") {
      this.router.navigateByUrl('/login');
    }
  }

  checkConsultant(): void {
    this.currentUser(UserCurrent);

    if (this.roles.indexOf(UserCurrent.roleName) < 0) {
      this.router.navigateByUrl('/login');
    }
  }

  capitalize(text: string): string {
    if (!text) {
      return "";
    }
    var words = text.split(" ");
    words = words.map(word => {
      if (word.length < 1) {
        return "";
      }
      if (word.length === 1) {
        return word.toUpperCase();
      }
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    });
    return words.join(" ");
  }

  stripNonNumeric(text: string): string {
    if (!text) {
      return "";
    }
    return text.replace(/[^0-9]/gi, "");
  }

  now(): string {
    var timeNow = new Date();
    var hour = String(timeNow.getHours()).padStart(2, "0");
    var min = String(timeNow.getMinutes()).padStart(2, "0");

    return `${hour}:${min}`;
  }

  dateFormat(dateString: string): string {
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      if (!dateString) {
          return "";
      }
      
      var d = new Date(dateString);
      if (d instanceof Date) {
          return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
      }
      return "";
  }

  getElementValue(el: Element): string {
    if (el.tagName === "SPAN") {
      return "";
    } else if (el.tagName === "TEXTAREA") {
        return (<HTMLTextAreaElement>el).value;
    } else if (el.tagName === "SELECT") {
      return (<HTMLSelectElement>el).value;
    } else if (el.tagName === "INPUT") {
      if ((<HTMLInputElement>el).type === "radio") {
        return (<HTMLInputElement>el).checked ? "Y" : "N";
      } else if ((<HTMLInputElement>el).type === "checkbox"){
        return (<HTMLInputElement>el).checked ? (<HTMLInputElement>el).value : "";
      } else {
        return (<HTMLInputElement>el).value;
      }
    }
    return "";
  }

  setElementValue(el: Element, val: string, answers: AnswerFull[]): void {
    if (el.tagName === "SPAN") {
      (<HTMLTextAreaElement>el).textContent = "Calculated";
    } else if (el.tagName === "TEXTAREA") {
      (<HTMLTextAreaElement>el).value = val;
    } else if (el.tagName === "SELECT") {
      (<HTMLSelectElement>el).value = val;
    } else if (el.tagName === "INPUT") {
      if ((<HTMLInputElement>el).type === "radio") {
        Array.from(document.getElementsByName((<HTMLInputElement>el).id)).forEach(r => {
          (<HTMLInputElement>r).checked = (val === (<HTMLInputElement>r).value);
        });
      } else if ((<HTMLInputElement>el).type === "checkbox") {
          (<HTMLInputElement>el).checked = (val === (<HTMLInputElement>el).value);
      } else if ((<HTMLInputElement>el).getAttribute("data-formula") || "" != "") {
        var cqTab = this.parseInputId(el.id || "");
        var answer = answers.find(a => a.formQuestionID == cqTab[0]) || {"formula": "", "answerTypeName": ""};

        (<HTMLInputElement>el).value = this.solveFormula(answer.formula || "", answers);
      } else {
        (<HTMLInputElement>el).value = val;
      }
    }
  }

  dateFromTime(time: string): number {
    var timeParsed = time.split(":");
    return +(new Date()).setUTCHours(parseInt(timeParsed[0], 10), parseInt(timeParsed[1], 10));
  }

  getValueFromFq(fqId: string, answers: AnswerFull[]): number {
    var answer = answers.find(a => parseInt(fqId) == a.formQuestionID) || {"answerText": "0", "answerTypeName": ""};
    var value = 0;
    
    if (answer.answerTypeName == "Date") {
      // cast as date
      value = +(new Date(answer.answerText));
    } else if (answer.answerTypeName == "Time") {
      // cast as date
      value = this.dateFromTime(answer.answerText);
    } else {
      // cast as number
      value = +answer.answerText;
    }

    return value;
  }

  msToHourMin(s: number): string {
    s = Math.round(s / 60000);
    var mins = s % 60;
    var minsPadded = "";
    var hrsPadded = "";
    if (mins < 10) {
      minsPadded = "0" + mins;
    } else {
      minsPadded = mins.toString(10);
    }
    var hrs = Math.floor(s / 60);
    if (hrs < 10) {
      hrsPadded = "0" + hrs;
    } else {
      hrsPadded = hrs.toString(10);
    }
  
    return `${hrsPadded}:${minsPadded}`;
  }

  timeDiff(time1: string, time2: string): number {
    var dateTime1 = new Date(time1);
    var dateTime2 = new Date(time2);
    return +dateTime2 - +dateTime1;
  }

  solveFormula(formula: string, answers: AnswerFull[]): string {
    var legalOperands = ["+", "-"]; // I'll add more when I need to
    // split into numbers and operators. Need to do this better eventually.
    // I'll want to add constants too
    if (!formula || answers.length <= 2) {
      console.log("!formula || answers.length <= 2");
      return "";
    }
    var splitFormula = formula.split(" ");
    var currentOperator = "";
    var currentOperand = 0;
    var previousOperand = 0;
    var currentAnswer = 0;
    var answerFormat = "";
    splitFormula.forEach(o => {
      if (legalOperands.indexOf(o) > -1) {
        currentOperator = o;
      } else if (parseInt(o, 10)) {
        currentOperand = this.getValueFromFq(o, answers);
        // Need to persist the calculated value too
        if (currentOperator == "+" && previousOperand) {
          currentAnswer = (previousOperand + currentOperand);
        } else if (currentOperator == "-" && previousOperand) {
          currentAnswer = previousOperand - currentOperand;
        }
        previousOperand = currentOperand;
        var answer = answers.find(a => parseInt(o) == a.formQuestionID) || {"answerText": "0", "answerTypeName": ""};
        answerFormat = answer.answerTypeName;
      }
    });

    if (answerFormat == "Time") {
      return this.msToHourMin(currentAnswer);
    } else if (answerFormat == "Date") {
      return this.dateFormat((new Date(currentAnswer).toDateString()));
    } else {
      return currentAnswer.toString(10);
    }
  }

  showSpinner(): void {
    var spinner = document.createElement("img");
    spinner.src = "assets/loader.gif";
    spinner.className = "spinner";
    (document.querySelector("body") || <HTMLElement>{}).append(spinner);
  }

  hideSpinner(): void {
    var spinner = document.querySelector("img.spinner") || <HTMLElement>{}
    if (spinner && spinner.remove) {
      spinner.remove();
    }
  }

  simpleHash(str: string): string {
    var hash = 0;
    for (let ii = 0; ii < str.length; ii += 1) {
      const char = str.charCodeAt(ii);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
  }

  parseInputId(id: string): number[] {
    var cqTabStr = id.split("-");
    var cqTab = [0, 0];

    if (cqTabStr.length != 2) {
      return cqTab;
    }
    if (cqTabStr[0].length <= 2) {
      return cqTab;
    }
    
    cqTab[0] = parseInt(cqTabStr[0].slice(2), 10);
    cqTab[1] = parseInt(cqTabStr[1], 10);
    
    return cqTab;
  }
}
