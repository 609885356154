import { Component, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Title } from "@angular/platform-browser";

import { InspectionFull, InspectionFormFull } from '../../data/inspection';
import { FormQuestionFull } from '../../data/form-question';
import { SelectOption } from '../../data/select-option';
import { AnswerFull } from '../../data/answer';

import { InspectionService } from '../../services/inspection.service';
import { FormQuestionService } from '../../services/form-question.service';
import { SelectOptionService } from '../../services/select-option.service';
import { AnswerService } from '../../services/answer.service';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.css']
})

export class ObservationComponent {
  inspectionID: number = 0;
  formID: number = 0;
  activeTab: number = 1;
  loaded = false;
  validated = true;
  tabHashSaved: string[] = [];
  tabHashCurrent: string[] = [];
  dataChanged: boolean = false;
  dataInOtherTabs: boolean = false;

  dealerName: string = "";
  inspectionDateFormatted: string = "";

  inspection: InspectionFull = <InspectionFull>{};
  inspectionFormFull: InspectionFormFull = <InspectionFormFull>{};
  inspectionFormsFull: InspectionFormFull[] = [];
  formQuestionsFull: FormQuestionFull[] = [];
  tabAnswers: AnswerFull[] = [];
  selectOptions: SelectOption[] = [];

  @ViewChild('tabs', { static: true }) tabs: ElementRef;

  constructor(
    private titleService: Title,
    public funcs: FuncsService,
    private route: ActivatedRoute,
    private inspectionService: InspectionService,
    private formQuestionService: FormQuestionService,
    private selectOptionService: SelectOptionService,
    private answerService: AnswerService,
    private userService: UserService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.funcs.showSpinner();
    this.funcs.checkConsultant();
    this.loadData(0);
  }

  loadData(tabOnly: number): void {
    const inspectionIDstring: string = this.route.snapshot.paramMap.get('inspectionID') || "";
    this.inspectionID = parseInt(inspectionIDstring, 10);
    const formIDstring: string = this.route.snapshot.paramMap.get('formID') || "";
    this.formID = parseInt(formIDstring, 10);

    let inspectionData = this.inspectionService.getInspectionFull(this.inspectionID);
    let formData = this.inspectionService.getInspectionFormsFull(this.inspectionID);
    let questionData = this.formQuestionService.getFullFormQuestions(this.formID);
    let selectOption = this.selectOptionService.getSelectOptions();

    forkJoin([inspectionData, formData, questionData, selectOption]).subscribe(responses => {
      this.inspection = responses[0];

      this.dealerName = this.funcs.capitalize(responses[0].dealerName);
      this.inspectionDateFormatted = ` ${this.funcs.dateFormat(responses[0].createdDate)}`;

      this.inspectionFormsFull = responses[1];
      this.inspectionFormFull = this.inspectionFormsFull[responses[1].findIndex(f => f.formID === this.formID)];

      this.formQuestionsFull = responses[2];
      this.selectOptions = responses[3];

      this.titleService.setTitle(`${this.inspectionFormFull.formName} - ${this.funcs.capitalize(this.inspection.dealerName)} - Helm Service Consulting`);
      
      this.tabHashSaved.length = 0;
      this.tabHashCurrent.length = 0;
      for (let ii = 1; ii <= this.inspectionFormFull.maxInstances; ii += 1) {
        this.tabHashSaved.push("");
        this.tabHashCurrent.push("");
      }
      this.buildTabs(tabOnly);
    });
  }

  otherForms(): InspectionFormFull[] {
    // exclude non displayed and the active form
    return this.inspectionFormsFull.filter(f => f.formID != this.formID);
  }

  changeActiveForm(formIndex: number): void {
    this.activeTab = formIndex;
    var tabForms = document.querySelectorAll("#tabs > div");
    Array.from(tabForms).forEach((t, idx) => {
      if (idx + 1 === formIndex) {
        t.classList.add("active");
      } else {
        t.classList.remove("active");
      }
    });

    this.dataChanged = this.hasDataChanged();
    this.dataInOtherTabs = this.unsavedDataInOtherTabs();
  }

  setHashes(): void {
    this.tabHashSaved = this.tabHashSaved.map((t, ii) => {
      return this.getHashFromTab(ii + 1);
    });
    this.tabHashCurrent = [...this.tabHashSaved];
  }

  setValue(a: AnswerFull, tabAnswers: AnswerFull[]): void {
    var formField: HTMLElement = <HTMLElement>{};
    var answerText = a.answerText;
    formField = document.querySelector(`#cq${a.formQuestionID}-${a.tabIndex}`) || <HTMLElement>{};
    formField.setAttribute("data-aid", a.answerID.toString(10));
    this.funcs.setElementValue(formField, answerText, tabAnswers);
  }

  getAnswers(tabOnly: number): void {
    this.answerService.getFullFormAnswers(this.inspectionID, this.formID).subscribe(answers => {
      this.tabAnswers = answers;
      answers.forEach(a => {
        if (tabOnly == 0 || tabOnly == a.tabIndex) {
          this.setValue(a, this.tabAnswers);
        }
      });

      // update hashmap
      this.setHashes();
      this.dataInOtherTabs = this.unsavedDataInOtherTabs();

      this.findEmptyTabs();
      this.loaded = true;
      this.funcs.hideSpinner();
    });
  }

  emptyTab(topTab: Element, tabIdx: number): void {
    if (this.isTabEmpty(tabIdx)) {
      topTab.classList.add("empty");
    } else {
      topTab.classList.remove("empty");
    }
  }

  findEmptyTabs(): void {
    var topTabs = document.querySelectorAll("nav.tabs li");
    for (let ii = 1; ii <= topTabs.length; ii += 1) {
      this.emptyTab(topTabs[ii - 1], ii);
    }
  }

  buildTab(ii: number): void {
    var tab: HTMLDivElement = <HTMLDivElement>{};
    if (document.querySelector(`#tabs > div#tab-${ii}`)) {
      tab = document.querySelector(`#tabs > div#tab-${ii}`) || <HTMLDivElement>{};
      tab.innerHTML = "";
      tab.append(this.buildQuestions(ii));
    } else {
      tab = this.renderer.createElement('div');
      tab.id = `tab-${ii}`;
      tab.className = this.activeTab === ii ? "active" : "";
      tab.append(this.buildQuestions(ii));
      this.renderer.appendChild(this.tabs.nativeElement, tab);
    }
  }

  buildTabs(tabOnly: number): void {
    if (tabOnly != 0) {
      this.buildTab(tabOnly);
    } else {
      for (let ii = 1; ii <= this.inspectionFormFull.maxInstances; ii += 1) {
        this.buildTab(ii);
      }
    }
    this.getAnswers(tabOnly);
  }

  buildQuestions(tabIdx: number): DocumentFragment {
    var sections = document.createDocumentFragment();
    var sectionIdx = this.formQuestionsFull[0].formSectionID;
    var section = document.createElement("section");
    const h2 = document.createElement("h2");
    if (this.inspectionFormFull.maxInstances > 1) {
      h2.textContent = `${this.inspectionFormFull.formName} #${tabIdx}`;
      section.append(h2);
    }
    if (this.formQuestionsFull[0].sectionTitle) {
      const h3 = document.createElement("h3");
      h3.textContent = this.formQuestionsFull[0].sectionTitle;
      section.append(h3);
    }
    
    if (this.formQuestionsFull[0].sectionDesc) {
      const h5 = document.createElement("h5");
      h5.innerHTML = this.formQuestionsFull[0].sectionDesc.replace(/(?:\r\n|\r|\n)/g, '<br>');
      section.append(h5);
    }

    this.formQuestionsFull.forEach(fq => {
      if (fq.formSectionID !== sectionIdx) {
        sectionIdx = fq.formSectionID;
        sections.append(section);
        section = document.createElement("section");
    
        if (fq.sectionTitle) {
          const h3 = document.createElement("h3");
          h3.textContent = fq.sectionTitle;
          section.append(h3);
        }
    
        if (fq.sectionDesc) {
          const h5 = document.createElement("h5");
          h5.innerHTML = fq.sectionDesc.replace(/(?:\r\n|\r|\n)/g, '<br>');
          section.append(h5);
        }
      }

      section.append(this.formInput(fq, tabIdx));
    });
    sections.append(section);
    return sections;
  }

  formInput(fq: FormQuestionFull, tabIdx: number): HTMLParagraphElement {
    var p = document.createElement("p");
    var label = document.createElement("label");
    var now = document.createElement("button");
    label.textContent = fq.questionText;
    if (fq.answerTypeName !== "Yes/No") {
      label.htmlFor = `cq${fq.formQuestionID}-${tabIdx}`;
    }
    if (fq.answerTypeName === "Check Box") {
      p.append(this.buildInput(fq, tabIdx));
      p.append(" ");
      label.className = "check";
      p.append(label);
    } else {
      p.append(label);
      p.append(" ");
      var inputElement = this.buildInput(fq, tabIdx);
      p.append(inputElement);

      // If Time, add Now button
      if (fq.answerTypeName === "Time") {
        now.type = "button";
        now.className = "now";
        now.textContent = "Now";
        now.id = `now${fq.formQuestionID}-${tabIdx}`;

        now.addEventListener("click", () => {
          (<HTMLInputElement>inputElement).value = this.funcs.now();
        });

        p.append(" ");
        p.append(now);
      }
    }
    return p;
  }

  buildYesNoInput(fq: FormQuestionFull, tabIdx: number): HTMLElement {
    var span = document.createElement("span");
    span.className = "yesno";
    var labelYes = document.createElement("label");
    var inputYes = document.createElement("input");
    inputYes.type = "radio";
    inputYes.id = `cq${fq.formQuestionID}-${tabIdx}`;
    inputYes.name = `cq${fq.formQuestionID}-${tabIdx}`;
    inputYes.value = "Y";
    labelYes.append(inputYes);
    labelYes.append(" Yes");
    span.append(labelYes);

    var labelNo = document.createElement("label");
    var inputNo = document.createElement("input");
    inputNo.type = "radio";
    inputNo.id = `cq${fq.formQuestionID}No-${tabIdx}`;
    inputNo.name = `cq${fq.formQuestionID}-${tabIdx}`;
    inputNo.value = "N";
    inputNo.checked = true;
    labelNo.append(inputNo);
    labelNo.append(" No");
    span.append(labelNo);

    return span;
  }

  buildSelect(fq: FormQuestionFull, tabIdx: number): HTMLElement {
    var select = document.createElement("select");
    select.id = `cq${fq.formQuestionID}-${tabIdx}`;
    this.selectOptions.filter(o => o.questionID === fq.questionID).forEach(o => {
      var option = document.createElement("option");
      option.value = o.answerValue;
      option.textContent = o.answerText || o.answerValue;
      option.selected = o.selected;
      select.append(option);
    });
    if (fq.required) {
      select.required = true;
    }

    return select;
  }

  buildInput(fq: FormQuestionFull, tabIdx: number): HTMLElement {
    interface Map {
      [key: string]: string
    }
    const inputTypes: Map = {
      "Date": "date",
      "Time": "time",
      "Number": "number",
      "Text": "text",
      "Check Box": "checkbox",
    };
    const MinMaxStep: string[] = ["Date", "Time", "Number"];

    if (fq.answerTypeName === "Text Area") {
      var textarea = document.createElement("textarea");
      textarea.id = `cq${fq.formQuestionID}-${tabIdx}`;
      textarea.name = `cq${fq.formQuestionID}-${tabIdx}`;
      if (fq.required) {
        textarea.required = true;
      }
      return textarea;
    }
    if (fq.answerTypeName === "Yes/No") {
      return this.buildYesNoInput(fq, tabIdx);
    }
    if (fq.answerTypeName === "Selection") {
      return this.buildSelect(fq, tabIdx);
    }
    var input = document.createElement("input");
    input.id = `cq${fq.formQuestionID}-${tabIdx}`;
    input.name = `cq${fq.formQuestionID}-${tabIdx}`;
    input.type = inputTypes[fq.answerTypeName];

    if (MinMaxStep.indexOf(fq.answerTypeName) > -1) {
      if (fq.min !== null) {
        input.min = fq.min.toString(10);
      }
      if (fq.max !== null) {
        input.max = fq.max.toString(10);
      }
      if (fq.step !== null) {
        input.step = fq.step.toString(10);
      }
      if (fq.required) {
        input.required = true;
      }
    } else if (fq.answerTypeName === "Check Box") {
      input.type = "checkbox";
      input.value = "Y";
    } else {
      input.type = "text";
      // add maxlength from max value
      if (fq.max) {
        input.maxLength = fq.max;
      }
      if (fq.required) {
        input.required = true;
      }
    }
    if (fq.answerTypeName === "Calculated") {
      input.disabled = true;
      input.setAttribute("data-formula", fq.formula);
    }
    return input;
  }

  getAnswerTypeNameFromElement(inp: Element): string {
    if (inp.nodeName == "TEXTAREA") {
      return "Text Area";
    }
    if (inp.nodeName == "SELECT") {
      return "Selection";
    }
    if (inp.nodeName == "INPUT") {
      if ((<HTMLInputElement>inp).type == "date") {
        return "Date";
      }
      if ((<HTMLInputElement>inp).type == "time") {
        return "Time";
      }
      if ((<HTMLInputElement>inp).type == "checkbox") {
        return "Check Box";
      }
      if ((<HTMLInputElement>inp).type == "radio") {
        return "Yes/No";
      }
      if ((<HTMLInputElement>inp).type == "number") {
        return "Number";
      }
      
    }
    return "Text";
  }

  getAnswerFull(inp: Element, tabIdx: number): AnswerFull {
    var formQuestionID: number = parseInt(inp.id.slice(2, inp.id.indexOf("-")), 10);
    var answerIDTxt = (<HTMLInputElement>inp).dataset['aid'] || "0";
    var answerID = parseInt(answerIDTxt, 10);

    var answer: AnswerFull = {
      "answerID": answerID,
      "formQuestionID": formQuestionID,
      "programID": this.inspection.programID,
      "dealerCode": this.inspection.dealerCode,
      "dealerName": this.inspection.dealerName,
      "inspectionTypeCode": this.inspection.inspectionTypeCode,
      "inspectionTypeName": this.inspection.inspectionTypeName,
      "tabIndex": tabIdx,
      "answerText": this.funcs.getElementValue(inp),
      "formID": 0,
      "formCode": this.inspectionFormFull.formCode,
      "formName": this.inspectionFormFull.formName,
      "formDisplayed": this.inspectionFormFull.formDisplayed,
      "reportLayout": "",
      "maxInstances": this.inspectionFormFull.maxInstances,
      "formSectionID": 0,
      "title": "",
      "desc": "",
      "questionText": "",
      "answerTypeName": this.getAnswerTypeNameFromElement(inp),
      "formula": inp.getAttribute("data-formula") || "",
      "required": false,
      "createdDate": (new Date()).toJSON(),
      "createdUserID": this.userService.getCurrentUser().userID,
      "updatedDate": (new Date()).toJSON(),
      "updatedUserID": this.userService.getCurrentUser().userID,
      "completedDate": (new Date()).toJSON(),
      "completedUserID": this.userService.getCurrentUser().userID
    }

    return answer;
  }

  isValid(inp: Element, tabIdx: number): boolean {
    var requiredTypes = ["text"];
    if (requiredTypes.indexOf((<HTMLInputElement>inp).type) >= 0) {
      if ((<HTMLInputElement>inp).required && (<HTMLInputElement>inp).value === "") {
        return false;
      }
    }
    return true;
  }

  getTabData(tabIdx: number): AnswerFull[] {
    var tabForm: HTMLElement = document.querySelector(`#tabs > div#tab-${tabIdx}`) || <HTMLElement>{};
    var tabFields = tabForm.querySelectorAll("input, textarea, select");
    var validated = true;
    this.validated = true;
    
    var formData: AnswerFull[] = [];
    Array.from(tabFields).forEach(f => {
      if (f.id && /^cq[0-9]*-[0-9]*$/.test(f.id)) {
        // does this tab field "have" data? (And maybe check that tabIndex is > 1)
        // instead, check for required fields. Each form should have at least one, otherwise, what's the point?
        // if (tabIdx > 0) {
        //   console.log('checking for data');
        // }
        if (!this.isValid(f, tabIdx)) {
          validated = false;
        }
        formData.push(this.getAnswerFull(f, tabIdx));
      }
    });

    this.validated = validated;
    return formData;
  }

  saveAnswers(formValues: AnswerFull[], tabOnly: number): void {
    this.answerService.addUpdateAnswers(this.inspectionID, formValues).subscribe(rsp => {
      if (formValues.some(a => a.answerID === 0)) {
        this.loadData(tabOnly);
        this.dataInOtherTabs = this.unsavedDataInOtherTabs();
      } else {
        this.setHashes();
        this.funcs.hideSpinner();
      }
      this.dataChanged = false;
    });
  }

  saveTab(): void {
    this.funcs.showSpinner();
    var formValues: AnswerFull[] = this.getTabData(this.activeTab);
    this.saveAnswers(formValues, this.activeTab);
  }

  saveAll(): void {
    var answers: AnswerFull[] = [];
    for (let ii = 1; ii <= this.inspectionFormFull.maxInstances; ii += 1) {
      if (!this.isTabEmpty(ii)) {
        answers.push(...this.getTabData(ii));
      }
    }
    this.saveAnswers(answers, 0);
  }

  isTabEmpty(tabIdx: number): boolean {
    var tabForm: HTMLElement = document.querySelector(`#tabs > div#tab-${tabIdx}`) || <HTMLElement>{};
    var tabFields = tabForm.querySelectorAll("input, textarea, select");
    var nonEmpty = ["radio", "checkbox"];
    
    var isEmpty = true;
    Array.from(tabFields).forEach(f => {
      if ((<HTMLInputElement>f).nodeName != "INPUT" || nonEmpty.indexOf((<HTMLInputElement>f).type) < 0) {
        if ((<HTMLInputElement>f).value != "") {
          isEmpty = false;
        }
      }
    });

    return isEmpty;
  }

  getTabFromField(el: HTMLElement): number {
    var id = el.id;
    var tabIdx = 0;
    var tabStr = "";
    if (id && id.indexOf("-")) {
      tabStr = id.slice(id.indexOf("-") + 1);
      if (parseInt(tabStr, 10) > 0 && parseInt(tabStr, 10) <= 6) {
        tabIdx = parseInt(tabStr, 10);
      }
    }
    return tabIdx;
  }

  getHashFromTab(tabIdx: number): string {
    const data = this.getTabData(tabIdx);
    const hash = this.funcs.simpleHash(data.map(d => d.answerText).join(""));
    return hash;
  }

  hasDataChanged(): boolean {
    return this.tabHashCurrent[this.activeTab - 1] != this.tabHashSaved[this.activeTab - 1];
  }

  unsavedDataInOtherTabs(): boolean {
    var unsaved = false;
    this.tabHashCurrent.forEach((h, ii) => {
      if (ii + 1 != this.activeTab) {
        if (h != this.tabHashSaved[ii]) {
          unsaved = true;
        }
      }
    });
    return unsaved;
  }

  recalculateFields(): void {
    var calculatedFields = this.formQuestionsFull.filter(a => a.answerTypeName == "Calculated");

    calculatedFields.forEach(a => {
      var formField: HTMLElement = document.querySelector(`#cq${a.formQuestionID}-${this.activeTab}`) || <HTMLElement>{};
      this.funcs.setElementValue(formField, "", this.getTabData(this.activeTab));
    });
  }

  onKeyUpOrClick(e: Event): void {
    // This also needs to be called on radio/checkbox/select change, date select, "now" button, etc.
    var tabIdx = this.getTabFromField((<HTMLElement>e.target));

    if (tabIdx && tabIdx > 0 && tabIdx <= 6) {
      this.tabHashCurrent[tabIdx - 1] = this.getHashFromTab(tabIdx);
    }
    this.dataChanged = this.hasDataChanged();
    // recalculate any calculated fields
    this.recalculateFields();
    this.findEmptyTabs();
  }
}
