<main>
  <header>
    <div>
      <p><a href="/home">{{title}}</a></p>
      <p><a [routerLink]="" *ngIf="!hideLogout" (click)="logOut()">Log Out</a></p>
    </div>
  </header>
  
  <article>
  <router-outlet></router-outlet>
  </article>
</main>
