<h1>Users</h1>

<section id="tabs">
<p><label>Email </label> <input type="text" name="user-email" [(ngModel)]="email" autocomplete="new-username"></p>
<p><label>Role </label>
    <select [(ngModel)]="role">
        <option value="13">Consultant</option>
        <option value="14">Admin</option>
    </select>
</p>
<p><label>Password </label> <input type="password" [(ngModel)]="password" autocomplete="new-password"></p>
<p><button (click)="createNewUser()">Create New User</button></p>
</section>

<section>
<div *ngFor="let user of users">
    <p><a href="/home/{{user.userID}}">{{user.userID}}</a> {{user.roleName}}</p>
</div>
</section>
