import { Component } from '@angular/core';
import { User } from '../../data/user';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {
  users: User[] = [];
  email: string = "";
  role: number = 13;
  password: string = "";

  constructor(
    private userService: UserService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.userService.getUsers().subscribe(users => {
      this.users = users;
    });
  }

  createNewUser(): void {
    this.userService.createUser(this.email, this.role, this.password).subscribe(rsp => {
      location.reload();
    });
  }
}
