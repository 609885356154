<h1>{{inspectionFormFull.formName}}</h1>
<p *ngIf="loaded"><a href="/inspection/{{inspectionID}}">{{inspection.inspectionTypeName}}</a> started {{inspectionDateFormatted}}<br>
    for {{funcs.capitalize(inspection.dealerName)}}
</p>
<p style="padding-left: 8px; ">Switch form to:
    <span *ngFor="let f of otherForms(); let first = first;">
        <ng-container *ngIf="!first"> • </ng-container>
        <a href="/observation/{{inspectionID}}/{{f.formID}}">{{f.formName}}</a>
    </span>
</p>

<nav *ngIf="inspectionFormFull.maxInstances > 1" class="tabs">
    <li *ngIf="inspectionFormFull.maxInstances > 0" [ngClass]="{active: activeTab === 1}" (click)="changeActiveForm(1)">1</li>
    <li *ngIf="inspectionFormFull.maxInstances > 1" [ngClass]="{active: activeTab === 2}" (click)="changeActiveForm(2)">2</li>
    <li *ngIf="inspectionFormFull.maxInstances > 2" [ngClass]="{active: activeTab === 3}" (click)="changeActiveForm(3)">3</li>
    <li *ngIf="inspectionFormFull.maxInstances > 3" [ngClass]="{active: activeTab === 4}" (click)="changeActiveForm(4)">4</li>
    <li *ngIf="inspectionFormFull.maxInstances > 4" [ngClass]="{active: activeTab === 5}" (click)="changeActiveForm(5)">5</li>
    <li *ngIf="inspectionFormFull.maxInstances > 5" [ngClass]="{active: activeTab === 6}" (click)="changeActiveForm(6)">6</li>
    <li *ngIf="inspectionFormFull.maxInstances > 6" [ngClass]="{active: activeTab === 7}" (click)="changeActiveForm(7)">7</li>
    <li *ngIf="inspectionFormFull.maxInstances > 7" [ngClass]="{active: activeTab === 8}" (click)="changeActiveForm(8)">8</li>
    <li *ngIf="inspectionFormFull.maxInstances > 8" [ngClass]="{active: activeTab === 9}" (click)="changeActiveForm(9)">9</li>
    <li *ngIf="inspectionFormFull.maxInstances > 9" [ngClass]="{active: activeTab === 10}" (click)="changeActiveForm(10)">10</li>
</nav>

<div id="tabs" #tabs (keyup)="onKeyUpOrClick($event)" (click)="onKeyUpOrClick($event)"></div>

<!-- This would look better as part of the last section -->
<!-- <section id="save-section"><p><button (click)="save()">Save</button></p></section> -->

<footer id="save-footer">
    <p>
        <button (click)="saveTab()" [disabled]="!dataChanged">Save Tab</button>
        <button (click)="saveAll()" [disabled]="!dataInOtherTabs">Save All Tabs</button>
    </p>
</footer>
