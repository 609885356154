import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './controllers/login/login.component';
import { HomeComponent } from './controllers/home/home.component';
import { AdminComponent } from './controllers/admin/admin.component';
import { UserComponent } from './controllers/user/user.component';
import { DealersComponent } from './controllers/dealers/dealers.component';
import { DealerComponent } from './controllers/dealer/dealer.component';
import { InspectionComponent } from './controllers/inspection/inspection.component';
import { ObservationComponent } from './controllers/observation/observation.component';
import { ReportComponent } from './controllers/report/report.component';
import { QuestionComponent } from './controllers/question/question.component';
import { QuestionsComponent } from './controllers/questions/questions.component';
import { FormQuestionsComponent } from './controllers/form-questions/form-questions.component';
import { FormQuestionComponent } from './controllers/form-question/form-question.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AdminComponent,
    UserComponent,
    DealersComponent,
    DealerComponent,
    InspectionComponent,
    ObservationComponent,
    ReportComponent,
    QuestionComponent,
    QuestionsComponent,
    FormQuestionsComponent,
    FormQuestionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
