import { Component } from '@angular/core';
import { User, UserCurrent } from '../../data/user';
import { Router } from '@angular/router';
import { FuncsService } from '../../services/funcs.service';
import {  } from '../../data/user';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent {
  user: User = UserCurrent;

  constructor(
    private router: Router,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.funcs.currentUser(UserCurrent);
  }
}
