import { Component } from '@angular/core';
import { FuncsService } from './services/funcs.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Service Consulting';
  public hideLogout = false;

  constructor(
    private funcs: FuncsService,
    private router: Router
  ) {
    router.events.subscribe(val => {
      // hide logout link on login page
      if (val instanceof NavigationEnd) {
        this.hideLogout = window.location.pathname === '/login';
      }
    });
  }

  logOut() {
    this.funcs.logOutUser();
  }
}
