export interface User {
    userID: string;
    roleID: number;
    roleName: string;
    consultantID: string;
}

export const Users: User[] = [];
export const UserCurrent: User = {
    userID: "",
    roleID: 0,
    roleName: "",
    consultantID: ""
};
