import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './controllers/home/home.component';
import { LoginComponent } from './controllers/login/login.component';
import { AdminComponent } from './controllers/admin/admin.component';
import { UserComponent } from './controllers/user/user.component';
import { DealersComponent } from './controllers/dealers/dealers.component';
import { DealerComponent } from './controllers/dealer/dealer.component';
import { InspectionComponent } from './controllers/inspection/inspection.component';
import { ObservationComponent } from './controllers/observation/observation.component';
import { ReportComponent } from './controllers/report/report.component';
import { QuestionComponent } from './controllers/question/question.component';
import { QuestionsComponent } from './controllers/questions/questions.component';
import { FormQuestionComponent } from './controllers/form-question/form-question.component';
import { FormQuestionsComponent } from './controllers/form-questions/form-questions.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'home/:UserID', component: HomeComponent, title: "Home - Helm Service Consulting"},
  {path: 'home', component: HomeComponent, title: "Consultant Home - Helm Service Consulting"},
  {path: 'login', component: LoginComponent, title: "Log In - Helm Service Consulting"},
  {path: 'admin', component: AdminComponent, title: "Admin - Helm Service Consulting"},
  {path: 'user', component: UserComponent, title: "Users - Helm Service Consulting"},
  {path: 'dealers', component: DealersComponent, title: "Dealers - Helm Service Consulting"},
  {path: 'dealer/:dealerID', component: DealerComponent, title: "Helm Service Consulting"},
  {path: 'questions', component: QuestionsComponent, title: "Questions - Helm Service Consulting"},
  {path: 'question/:questionID', component: QuestionComponent, title: "Helm Service Consulting"},
  {path: 'form-questions', component: FormQuestionsComponent, title: "Form Questions - Helm Service Consulting"},
  {path: 'form-question/:formQuestionID', component: FormQuestionComponent, title: "Helm Service Consulting"},
  {path: 'inspection', component: InspectionComponent, title: "Helm Service Consulting"},
  {path: 'inspection/:inspectionID', component: InspectionComponent, title: "Helm Service Consulting"},
  {path: 'observation/:inspectionID/:formID', component: ObservationComponent, title: "Helm Service Consulting"},
  {path: 'report/:inspectionID', component: ReportComponent, title: "Report - Helm Service Consulting"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
