import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Answer, AnswerFull } from '../data/answer';

@Injectable({
  providedIn: 'root',
})
export class AnswerService {
  private apiEndpoint = `${environment.apiUrl}/api/Answer`;

  constructor(private http: HttpClient) { }

  addAnswer(Answer: Answer): Observable<Answer> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<Answer>(url, Answer)
      .pipe(
        tap(_ => this.log('Added Answer')),
        catchError(this.handleError<Answer>('addAnswer'))
      );
  }

  getFormAnswers(InspectionID: number, FormID: number): Observable<Answer[]> {
    const url = `${this.apiEndpoint}/Form/${InspectionID}/${FormID}`;
    return this.http.get<Answer[]>(url)
      .pipe(
        tap(_ => this.log('fetched Answers')),
        catchError(this.handleError<Answer[]>('get Form Answers'))
      );
  }

  getFullFormAnswers(InspectionID: number, FormID: number): Observable<AnswerFull[]> {
    const url = `${this.apiEndpoint}/Full/${InspectionID}/${FormID}`;
    return this.http.get<AnswerFull[]>(url)
      .pipe(
        tap(_ => this.log('fetched full Answers')),
        catchError(this.handleError<AnswerFull[]>('get Full Form Answers'))
      );
  }

  getAnswer(AnswerID: string): Observable<Answer> {
    const url = `${this.apiEndpoint}/${AnswerID}`;
    return this.http.get<Answer>(url)
      .pipe(
        tap(_ => this.log('fetched Answer')),
        catchError(this.handleError<Answer>('getAnswer'))
      );
  }

  getAnswers(): Observable<Answer[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<Answer[]>(url)
      .pipe(
        tap(_ => this.log('fetched Answers')),
        catchError(this.handleError<Answer[]>('getAnswers'))
      );
  }

  getAnswersFull(InspectionId: number): Observable<AnswerFull[]> {
    const url = `${this.apiEndpoint}/Full/${InspectionId}`;
    return this.http.get<AnswerFull[]>(url)
      .pipe(
        tap(_ => this.log('fetched full Answers')),
        catchError(this.handleError<AnswerFull[]>('getAnswersFull'))
      );
  }

  addUpdateAnswers(InspectionID: number, Answers: AnswerFull[]): Observable<Answer> {
    const url = `${this.apiEndpoint}/Form/${InspectionID}`;
    return this.http.put<Answer>(url, Answers)
      .pipe(
        tap(_ => this.log('Merged Answer')),
        catchError(this.handleError<Answer>('addUpdateAnswers'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}