import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

import { Dealer } from '../../data/dealer';
import { Inspection, InspectionFull } from '../../data/inspection';
import { InspectionType } from '../../data/inspection-type';

import { DealerService } from '../../services/dealer.service';
import { InspectionService } from '../../services/inspection.service';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { FuncsService } from '../../services/funcs.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.css']
})
export class DealerComponent {
  dealerID: string = "";
  dealer: Dealer = <Dealer>{};
  dealerName: string = "";
  dealerCode: string = "";
  inProgress: InspectionFull[] = [];
  inProgressCount: number = 0;
  completed: InspectionFull[] = [];
  completedCount: number = 0;
  inspectionTypes: InspectionType[] = [];
  ins: Inspection = <Inspection>{};

  constructor(
    private titleService: Title,
    private dealerService: DealerService,
    public funcs: FuncsService,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionService: InspectionService,
    private inspectionTypeService: InspectionTypeService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.dealerID = this.route.snapshot.paramMap.get('dealerID') || "";
    this.funcs.showSpinner();
    this.dealerService.getDealer(this.dealerID).subscribe(dealer => {
      this.dealerName = this.funcs.capitalize(dealer.dealerName);
      this.dealerCode = dealer.dealerCode;
      this.dealer = dealer;
      this.titleService.setTitle(`${this.funcs.capitalize(this.dealer.dealerName)} - Helm Service Consulting`);
      this.getInspectionTypes();
    });
  }

  getInspections(): void {
    this.inspectionService.getInspectionsFull(this.dealer.dealerID, "").subscribe(result => {
      result.forEach(i => {
        if (i.completedDate) {
          this.completed.push(i);
        } else {
          this.inProgress.push(i);
        }
      });
      this.inProgressCount = this.inProgress.length;
      this.completedCount = this.completed.length;
      this.funcs.hideSpinner();
    },
    error => {
      this.funcs.hideSpinner();
      console.log(error);
    });
  }

  getInspectionTypes(): void {
    this.inspectionTypeService.getInspectionTypes().subscribe(inspectionTypes => {
      this.inspectionTypes = inspectionTypes;
      var defaultInspectionType = inspectionTypes.find(it => it.inspectionTypeDefault);
      if (defaultInspectionType) {
        this.ins.inspectionTypeID = defaultInspectionType.inspectionTypeID;
      }
      
      this.getInspections();
    });
  }

  addNewInspection(): void {
    this.funcs.showSpinner();
    this.ins.programID = 202;
    this.ins.dealerID = parseInt(this.dealerID, 10);
    this.ins.createdUserID = this.userService.getCurrentUser().userID;

    this.inspectionService.addInspection(this.ins).subscribe(result => {
      if (result === 1) {
        this.funcs.hideSpinner();
        this.router.navigateByUrl('/inspection');
      }
    },
    error => {
      this.funcs.hideSpinner();
      console.log(error);
    });
  }

  changeInspectionType(e: Event) {
    var target = <HTMLInputElement>e.target;
    var value = target.value || "";
    this.ins.inspectionTypeID = parseInt(value, 10);
  }
}
